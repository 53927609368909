<template>
  <div class="content-template">
    <div class="card animate__animated animate__fadeIn duration" id="card1">
        <div class="card_box_one">
          <div class="card_box_title2">
            <p class="font-blod font-24">公募基金中报</p>
            <p>持仓出炉</p>
            <p>{{ dataTime }}</p>
          </div>
          <div class="height-100 width-100">
            <video
              src="video/video6.mp4"
              muted
              width="100%"
              height="100%"
              ref="video1"
            ></video>
          </div>
          <div class="logo-box logo-box3">
            <img src="../../assets/img/logo2.png" alt="" />
          </div>
        </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="main-content">
        <div class="header">
          <p>
            <span class="font-24 title">公募基金</span>
            <span class="margin-l-18 color_#3A56D9">情况预览</span>
          </p>
          <p class="color_#3A56D9">{{dataTime}}</p>
        </div>
        <div class="describe bgcolor-DEDBFF">
          <p>(一)公募基金资产配置情况</p>
          <p class="margin-t-10">
            2021年中报显示,公募基金资产配置中,债券市值最高,达10.74万亿元,占比43.57%;股票6.29万亿元,占比25.5%。
          </p>
        </div>
        <div class="chart-box">
          <div id="chart1" style="width: 100%; height: 100%"></div>
        </div>
        <div class="text-center note">
          <p>数据来源：弹指文</p>
          <p>注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="main-content">
        <div class="header">
          <p>
            <span class="font-24 title">公募基金</span>
            <span class="margin-l-18 color-FF8800">情况预览</span>
          </p>
          <p class="color-FF8800">{{dataTime}}</p>
        </div>
        <div class="describe bgcolor-FFDEC5">
          <p>(二)基金行业投资情况</p>
          <p class="margin-t-10">
            在股票资产中,中报公募基金投资占比最高的依然是制造业,持仓市值达到39,732.46亿元,占股票投资市值比例的63.19%。其次为金融行业,市值为4,824.26亿元。
          </p>
        </div>
        <div class="chart-title">
          <span>行业名称</span>
          <span class="font-12">占股票投资市值比</span>
          <span class="font-12">市值(亿元)</span>
        </div>
        <div class="chart-box">
          <div id="chart2" style="width: 100%; height: 100%"></div>
        </div>
        <div class="text-center note">
          <p>数据来源：弹指文</p>
          <p>注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="main-content">
        <div class="header">
          <p>
            <span class="font-24 title">公募基金</span>
            <span class="margin-l-18 color-B21AA3">情况预览</span>
          </p>
          <p class="color-B21AA3">{{dataTime}}</p>
        </div>
        <div class="describe bgcolor-FFE2F1">
          <p>(三)基金重仓持股市值TOP10</p>
          <p class="margin-t-10">
            公募基金的前十大重仓股中,基金持股总市值最高为贵州台,达到1810.33亿元,宁德时代持仓市值排名第二,达到1419.9亿元。
          </p>
        </div>
        <div class="title3">
          <span class="font-18">TOP10</span>
          <span class="unit">持股总市值(亿元)</span>
        </div>
        <div class="chart-box">
          <div id="chart3" style="width: 100%; height: 100%"></div>
        </div>
        <div class="text-center note">
          <p>数据来源：弹指文</p>
          <p>注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="main-content">
        <div class="header">
          <p>
            <span class="font-24 title">公募基金</span>
            <span class="margin-l-18">情况预览</span>
          </p>
          <p>{{ dataTime }}</p>
        </div>
        <div class="describe">
          <p>(四)基金持股占流通股比增加</p>
          <p class="margin-t-10">
            相比一季度,公募基金本期持股增持比例排名第一的是建龙微纳,持股占流通比增减22.79%;贝泰妮排名第二,增长19.97%。
          </p>
        </div>
        <div class="chart-title">
          <span>股票名称</span>
          <span>持股占流通比增减（%）</span>
        </div>
        <div class="chart-box">
          <div id="chart4" style="width: 100%; height: 100%"></div>
        </div>
        <div class="text-center note">
          <p>数据来源：弹指文</p>
          <p>注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="main-content">
        <div class="header">
          <p>
            <span class="font-24 title">公募基金</span>
            <span class="margin-l-18 color-B21AA3">情况预览</span>
          </p>
          <p class="color-B21AA3">{{dataTime}}</p>
        </div>
        <div class="describe bgcolor-FFE2F1">
          <p>(五)基金重仓股市值增加TOP10</p>
          <p class="margin-t-10">
            相比一季度，公募基金重仓股中,宁德时代持仓市值增加排名第一,达797.62亿元,排在第二的是药明康德,增仓市值325.93亿元。
          </p>
        </div>
        <div class="title3">
          <span class="font-18">TOP10</span>
          <span class="unit">持仓市值增减(亿元)</span>
        </div>
        <div class="chart-box">
          <div id="chart5" style="width: 100%; height: 100%"></div>
        </div>
        <div class="text-center note">
          <p>数据来源：弹指文</p>
          <p>注：相关内容不对各位读者构成任何投资建议，据此操作，风险自担</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { NumberEvent } from "@/utils/number";
import { GetParams2 } from "@/utils/common";
import ApiServe from '@/api/index'
export default {
  name: "Template6",
  data() {
    return {
      dataTime:localStorage.getItem('dataTime'),
      chart_data_1: "",
      aduioData: [],
      record_time: 65,
      chartData1: [],
      xdata2: [],
      ydata2: [],
      percent2: [],
      xdata3: [],
      xdata4: [],
      ydata4: [],
      xdata5: [],
      id:'',
      model_id:null,
      model_name:"",
      model_category:'',
      url_extra_params:null,
      ip:'',
      port:'',
      offset_x:'',
      offset_y:'',
      width:"",
      height:'',
      source:''
    };
  },
  created() {
    this.id = GetParams2().id || null
    this.model_id = GetParams2().model_id || 6
    this.model_name = GetParams2().model_name || null
    this.model_category = GetParams2().model_category || null
    this.url_extra_params = GetParams2().url_extra_params || null
    this.ip = GetParams2().ip || null
    this.port = GetParams2().port || null
    this.offset_x = GetParams2().offset_x || null
    this.offset_y = GetParams2().offset_y || null
    this.width = GetParams2().width || null
    this.height = GetParams2().height || null
    this.source = GetParams2().source || null
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { chart_data_1, aduioData } = this;
      return {
        chart_data_1,
        aduioData,
      };
    },
    newIpPort(){
      if(this.source == 'ali'){
        return 'https://recordvideoali.wedengta.com'
      }else{
        return 'https://recordvideo.wedengta.com'
      }
    }
  },
  watch: {
    allReady(val) {
      if (val.chart_data_1.length > 0 && val.aduioData.length>0) {
        if (this.ip && this.port) {
          const params = {
            ip:this.ip,
            port:this.port,
            offset_x:this.offset_x,
            offset_y:this.offset_y,
            width:this.width,
            height:this.height
          }
          ApiServe.recordstartPc(this.newIpPort,params).then(({code}) => {
            
          }).catch((err)=>{
            ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
          });
          this.load();
        } else {
          alert('没获取到ip,port')
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getDataPC(params).then(({data,code})=>{
        if (code == 200) {
            this.chart_data_1 = data.chart_data_1 || [];
            this.chartData1 = data.chart_data_1[0].data.ASSET_ACCOUNT.map(
              (item, index) => {
                return {
                  name: item,
                  value: data.chart_data_1[0].data['市值'][index],
                  percent: data.chart_data_1[0].data['占总值比'][index],
                };
              }
            );
            this.xdata2 = data.chart_data_2[0].data.INDUSTRY_NAME;
            this.ydata2 = data.chart_data_2[0].data['市值'];
            this.percent2 = data.chart_data_2[0].data['占股票投资市值比'];
            this.xdata3 = data.chart_data_3[0].data.STOCK_NAME.map(
              (item, index) => {
                return {
                  name: item,
                  value: data.chart_data_3[0].data.tt[index],
                };
              }
            );
            this.xdata4 = data.chart_data_4[0].data["股票名称"];
            this.ydata4 = data.chart_data_4[0].data["qq"];
            this.xdata5 = data.chart_data_5[0].data["股票名称"].map(
              (item, index) => {
                return {
                  name: item,
                  value: data.chart_data_5[0].data.qq[index],
                };
              }
            );
            if (this.ip && this.chart_data_1.length==0) {
              ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
            }
          }
      }).catch((err)=>{
        if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
      })
    },
    // 获取旁白
    getAudio() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getVideoDataPC(params).then(({data,code})=>{
        if (code == 200) {
            this.aduioData = data || []
            if (this.ip && this.aduioData.length ==0) {
              ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
            }
          }
      }).catch((err)=>{
        if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
      })
    },
    // 公募基金资产配置情况
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        textStyle:{
          fontSize:24
        },
        legend: {
          top: "top",
          left: "0",
          right: "0",
          // width: "200px",
          // orient: 'vertical',
          // left: 'right',
        },
        animationDuration: 4000,
        series: [
          {
            name: "市值",
            type: "pie",
            data: this.chartData1,
            minAngle: 30,
            radius: ["10%", "45%"],
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                label: {
                  position: "inner",
                  textStyle: {
                    color: "#000",
                  },
                  formatter: function (value) {
                    return value.data.percent + "%";
                  },
                },
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#8596FF",
                    "#5AB7FF",
                    "#F2C941",
                    "#FFA1A1",
                    "#FF68A0",
                    "#F491FF",
                    "#41F2E0",
                    "#A1FFA7",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
          {
            name: "占比",
            type: "pie",
            minAngle: 30,
            data: this.chartData1,
            radius: ["45%", "75%"],
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                label: {
                  position: "inner",
                  textStyle: {
                    color: "#000",
                  },
                  formatter: function (value) {
                    return value.value;
                  },
                },
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#CACCFF",
                    "#BCE2FF",
                    "#F9E9B2",
                    "#FFD9D9",
                    "#FFC2D1",
                    "#FAD2FF",
                    "#B2F9F2",
                    "#D9FFDB",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 基金行业投资情况
    loadChart2() {
      // 基于准备好的dom，初始化this.$echarts实例
      const _this = this;
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      let option = {
        textStyle:{
          fontSize:24
        },
        grid: {
          left: "188px",
          right: "160px",
          top: "0",
          bottom: "0",
        },
        animationDuration: 4000,
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: [
          {
            type: "category",
            name: "行业名称",
            inverse: true,
            data: this.xdata2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              fontSize:24
            }
          },
          {
            type: "category",
            name: "市值",
            inverse: true,
            data: this.ydata2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              fontSize:24
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata2,
            barWidth: 32,
            barMinHeight:120,
            barGap: "40px",
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#FFF597", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#E66501", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                label: {
                  show: true, //开启显示
                  // position: "right", //在上方显示
                  color:"#fff",
                  offset: [20, 0],
                  fontSize:24,
                  formatter: function (value) {
                    return _this.percent2[value.dataIndex] + "%";
                  },
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 基金重仓持股
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      // 指定图表的配置项和数据
      let option = {
        textStyle:{
          fontSize:24
        },
        animationDuration: 4000,
        series: [
          {
            type: "treemap",
            data: this.xdata3,
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            breadcrumb: {
              show: false,
            },
            levels: [
              {
                color: [
                  "RGBA(255, 94, 83, 1)",
                  "RGBA(255, 94, 83, 0.9)",
                  "RGBA(255, 94, 83, 0.8)",
                  "RGBA(255, 94, 83, 0.7)",
                  "RGBA(255, 94, 83, 0,6)",
                  "RGBA(255, 94, 83, 0.5)",
                  "RGBA(255, 94, 83, 0.4)",
                  "RGBA(255, 94, 83, 0.3)",
                  "RGBA(255, 94, 83, 0.2)",
                  "RGBA(255, 94, 83, 0.1)",
                  "RGBA(255, 94, 83, 0.05)",
                ],
              },
            ],
            label: {
              show: true,
              color: "#000",
              formatter: function (value) {
                return value.name + "\n" + "\n" + value.value;
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金持股占流通股比增加
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      // 指定图表的配置项和数据
      let option = {
        textStyle:{
          fontSize:24
        },
        grid: {
          left: "120px",
          right: "0",
          top: "20px",
          bottom: "20px",
        },
        animationDuration: 4000,
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.xdata4,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              fontSize:24
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata4,
            barWidth: 32,
            barMinHeight:120,
            barGap: "80px",
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#D2FAFF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#004ED5", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                label: {
                  show: true, //开启显示
                  // position: "right", //在上方显示
                  fontSize:24,
                  color:"#fff",
                  offset: [20, 0],
                  formatter: function (value) {
                    return value.value + "%";
                  },
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // (五)基金重仓股市值增加TOP10
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 指定图表的配置项和数据
      let option = {
        textStyle:{
          fontSize:24
        },
        animationDuration: 4000,
        series: [
          {
            type: "treemap",
            data: this.xdata5,
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            breadcrumb: {
              show: false,
            },
            levels: [
              {
                color: [
                  "RGBA(255, 94, 83, 1)",
                  "RGBA(255, 94, 83, 0.9)",
                  "RGBA(255, 94, 83, 0.8)",
                  "RGBA(255, 94, 83, 0.7)",
                  "RGBA(255, 94, 83, 0,6)",
                  "RGBA(255, 94, 83, 0.5)",
                  "RGBA(255, 94, 83, 0.4)",
                  "RGBA(255, 94, 83, 0.3)",
                  "RGBA(255, 94, 83, 0.2)",
                  "RGBA(255, 94, 83, 0.1)",
                  "RGBA(255, 94, 83, 0.05)",
                ],
              },
            ],
            label: {
              show: true,
              color: "#000",
              formatter: function (value) {
                return value.name + "\n" + "\n" + value.value;
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    dataNumber(arr, idName, name) {
      for (let i = 0; i < arr.length; i++) {
        let str = `<p id="datanumber${i}" class="numerbox">${arr[i]}</p>`;
        $(idName).append(str);
      }
      for (let i = 0; i < arr.length; i++) {
        var datanum = NumberEvent.number(String(arr[i]));
        let par = `#datanumber${i}`;
        $(par).html(NumberEvent.dom(datanum));
        NumberEvent.animation();
      }
    },
    endRecord(){
      let arr =[3,18,29,41,51]
      let audiosOrderList =  this.aduioData.map((item,index)=>{
        return {
          'audioLink':item.audio_link,
          'timeIndex':arr[index],
          'audioLen':item.audio_length,
        }
      })
      const data = {
        'id':this.id, 
        'model_id':this.model_id,
        'videoTotalTime':this.record_time,
        'videmBgm':"https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        'audiosOrderList':audiosOrderList
      }
      ApiServe.recordstopPc(this.newIpPort,{ip:this.ip,port:this.port}).then(()=>{
        ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data).then(()=>{
           ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        }).catch(()=>{
          ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        })
      }).catch(()=>{
        ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
      })
    },
    load() {
      this.$refs.video1.play(); //首页的视频播放
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
      }, 3000);          //间隔
      setTimeout( ()=> {
        $("#card2").addClass("animate__fadeOut");
      }, 17000);
      setTimeout( ()=> {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
        this.dataNumber(this.ydata2, ".data-number3");
      }, 18000);     //间隔
      setTimeout( ()=> {
        $("#card3").addClass("animate__fadeOut");
      }, 28000);
      setTimeout( ()=> {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.loadChart3();
      }, 29000);   //间隔
      setTimeout( ()=> {
        $("#card4").addClass("animate__fadeOut");
      }, 40000);
      setTimeout( ()=> {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.loadChart4();
      }, 41000);   //间隔
      setTimeout( ()=> {
        $("#card5").addClass("animate__fadeOut", "pe");
      }, 50000);
      setTimeout( ()=> {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart5();
      }, 51000);   //间隔
      setTimeout( ()=> {
        $("#card6").addClass("animate__fadeOut");
      }, 59000);
      setTimeout( ()=> {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.endVideo.play()
      }, 60000);
      // 总时间65秒
      if(this.ip){
        setTimeout( ()=> {
          this.endRecord()
        }, 65000); 
      }
    },
  },
};
</script>
<style scoped lang="less">
@import '@/assets/css/common.less';
.content-template {
  background: url("../../assets/img/gr.jpg") 100% 100%;
  background-size: cover;
}
.logo-box{
  position: absolute;
  width: 210px*@beishu;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}
.logo-box3{
  bottom: 5%;
}
.container {
  color: #000;
}
.card {
  width: auto;
  background: transparent;
  color: #000;
  padding: 0 24px;
}
#card1{
  background: #D6E3E7;
  padding: 0;
}
#card1 .main-content{
  left: 0;
  right: 0;
}
.main-content{
  height: 90%;
  border: 1px cadetblue;
  position: absolute;
  top: 5%;
  left:24px;
  right: 24px;
}
#card1 .card_box_title2 {
  border: none;
}
.header {
  height: 60px*@beishuHeight;
  line-height: 60px*@beishuHeight;
  display: flex;
  justify-content: space-between;
  font-size: 18px*@beishu;
}
.title {
  color: #000;
}
.describe {
  opacity: 0.67;
  border-radius: 6px;
  padding: 16px 24px;
  line-height: 26px*@beishu;
}
.chart-box {
  width: 100%;
  height: 500px;
  margin-top: 10px;
}
#card3 .chart-box {
  height: 500px;
  margin-top: 0;
}
.chart-title {
  display: flex;
  justify-content: space-between;
  height: 40px*@beishuHeight;
  line-height: 40px*@beishuHeight;
  /* font-size: 16px; */
}
.chart-title span:first-of-type {
  width: 94px*@beishu;
  text-align: center;
}
.chart-title span:nth-of-type(3) {
  width: 90px*@beishu;
  text-align: center;
}
.title3 {
  text-align: center;
  position: relative;
  margin: 8px 0;
}
.title3 .unit {
  position: absolute;
  right: 0;
  top: 4px;
  font-size: 12px*@beishu;
}
.title3 span:first-of-type {
  color: #ff5e53;
}
#card5 .header p:nth-of-type(2) {
  color: #01aca7;
}
#card5 .header span:nth-of-type(2) {
  color: #01aca7;
}
#card5 .describe {
  background: #e2fffa;
}
#card5 .chart-title span:first-of-type {
  text-align: left;
}
#card6 .header p:nth-of-type(2) {
  color: #df9500;
}
#card6 .header span:nth-of-type(2) {
  color: #df9500;
}
#card6 .describe {
  background: #fffdc9;
}
#card7{
  padding: 0;
}
</style>